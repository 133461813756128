import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { CompactContext } from "../../App";
//---- MUI -------------------------//
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
//---- 3RD PARTY -------------------//
//---- COMPONENTS ------------------//

export default function FormStepper({ formStep, steps }) {

  const compact = useContext(CompactContext);


  //--- SETTERS ----------------------//
  //--- CONSTANTS --------------------//
  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={formStep}
        alternativeLabel
        sx={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: compact ? '0.9rem' : '1rem'
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

  // Declare the PropTypes for each prop
  FormStepper.propTypes = {
    formStep: PropTypes.number,
    steps: PropTypes.array,
  };
