import * as yup from "yup";
import {
  yupFirstName,
  yupLastName,
  yupFullName,
  yupEmail,
  yupPhone,
  yupPostcode,
  yupOutcode,
  yupPropertySize,
  yupPropertyValue,
  yupPropertyType,
  yupAppointWhen,
  yupLoanAmount,
  yupPropertyValueSecurity1,
  yupMortgageBalance1,
  yupAddressLine1,
  yupDevelopmentName,
} from "./yupSchema";

export const getFormSchema = (service, formType, formStep) => {
  if (service === "SpecialistLending") {
    return yup.object().shape({
      firstName: yupFirstName,
      lastName: yupLastName,
      email: yupEmail,
      phone: yupPhone,
    });
  } else if (service === "BridgingLoanCalculator") {
    return yup.object().shape({
      loanAmount: yupLoanAmount,
      propertyValueSecurity1: yupPropertyValueSecurity1,
      mortgageBalance1: yupMortgageBalance1,
    });
  } else if (service === "EA4Me" || service === "EA4Me2024") {
    return yup.object().shape({
      postcode: yupPostcode,
      propertyType: yupPropertyType,
      propertySize: yupPropertySize,
      propertyValue: yupPropertyValue,
      appointWhen: yupAppointWhen,
    });
  } else if (service === "LeaseExpert") {
    if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
      });
    } else {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
      });
    }
  } else if (service === "BestEA") {
    return yup.object().shape({
      postcode: yupPostcode,
      propertyType: yupPropertyType,
      propertySize: yupPropertySize,
      propertyValue: yupPropertyValue,
      appointWhen: yupAppointWhen,
    });
  } else if (service === "Snagging") {
    if (formType === "FormType2" && formStep === 0) {
      return yup.object().shape({
        developmentName: yupDevelopmentName,
        addressLine1: yupAddressLine1,
        postcode: yupPostcode,
        propertySize: yupPropertySize,
      });
    } else if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
      });
    } else {
      return yup.object().shape({
        developmentName: yupDevelopmentName,
        addressLine1: yupAddressLine1,
        postcode: yupPostcode,
        propertySize: yupPropertySize,
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
      });
    }
  } else if (
    service === "Architect" ||
    service === "ExpertWitness" ||
    service === "PartyWall" ||
    service === "Planning" ||
    service === "StructuralCalculations"
  ) {
    if (formType === "FormType2" && formStep === 0) {
      return yup.object().shape({
        postcode: yupOutcode,
      });
    } else if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    } else {
      return yup.object().shape({
        postcode: yupOutcode,
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    }
  } else if (
    service === "EPC" ||
    service === "ValuationSurvey" ||
    service === "HomebuyerSurvey"
  ) {
    if (formType === "FormType2" && formStep === 0) {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
        propertyValue: yupPropertyValue,
      });
    } else if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    } else {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
        propertyValue: yupPropertyValue,
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    }
  } else if (service === "RightToLight") {
    return yup.object().shape({
      postcode: yupOutcode,
      fullName: yupFullName,
      email: yupEmail,
      phone: yupPhone,
    });
  } else if (service === "FloodRiskSurvey") {
    return yup.object().shape({
      postcode: yupOutcode,
      fullName: yupFullName,
      email: yupEmail,
      phone: yupPhone,
    });
  } else if (service === "PropertyDisputes") {
    return yup.object().shape({
      fullName: yupFullName,
      email: yupEmail,
      phone: yupPhone,
    });
  } else if (service === "AsbestosSurvey") {
    return yup.object().shape({
      postcode: yupOutcode,
      fullName: yupFullName,
      email: yupEmail,
      phone: yupPhone,
    });
  } else if (
    service === "BuildingSurvey" ||
    service === "BuildingSurveyRemarketing"
  ) {
    if (formType === "FormType2" && formStep === 0) {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
        propertyValue: yupPropertyValue,
      });
    } else if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        fullName: yupFullName,
        email: yupEmail,
        phone: yupPhone,
      });
    } else {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
        propertyValue: yupPropertyValue,
        fullName: yupFullName,
        email: yupEmail,
        phone: yupPhone,
      });
    }
  } else if (service === "StructuralInspections") {
    if (formType === "FormType2" && formStep === 0) {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
      });
    } else if (formType === "FormType2" && formStep === 1) {
      return yup.object().shape({
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    } else {
      return yup.object().shape({
        postcode: yupOutcode,
        propertySize: yupPropertySize,
        firstName: yupFirstName,
        lastName: yupLastName,
        email: yupEmail,
        phone: yupPhone,
        termsAndConditions: yup
          .bool()
          .oneOf([true], "Please accept the Terms and Conditions"),
      });
    }
  } else {
    return yup.object().shape({
      developmentName: yupDevelopmentName,
      addressLine1: yupAddressLine1,
      propertySize: yupPropertySize,
      firstName: yupFirstName,
      lastName: yupLastName,
      email: yupEmail,
      phone: yupPhone,
    });
  }
};
