import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Form from './Form';

export default function MultiForm({ service, formType, multiPart, showHeading, showHeader, showFooter }) {
  const [formStep, setFormStep] = useState(0);
  const [showReCaptcha, setShowReCaptcha] = useState(false);

  //console.log(formStep);

  useEffect(() => {
    //console.log("useEffect for handling popstate triggered");

    const handlePopState = (event) => {
      //console.log("popstate event detected. event.state:", event.state);
    
      // Check and update URL if 'submitted=true' is present
      let url = new URL(window.location.href);
      if (url.searchParams.has("submitted")) {
        url.searchParams.delete("submitted");
        window.history.replaceState(event.state, '', url.toString());
        //console.log("Updated URL after removing 'submitted' param:", url.toString());
      }
    
      if (event.state && typeof event.state.formStep !== 'undefined') {
        //console.log("Setting formStep with value from event.state:", event.state.formStep);
        setFormStep(event.state.formStep);
      } else {
        //console.warn("popstate event state is not as expected or formStep is undefined");
      }
    };
    

    // Listen for popstate events (like pressing the back button)
    window.addEventListener('popstate', handlePopState);

    return () => {
        //console.log("Cleanup: Removing popstate event listener");
        window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    //console.log("useEffect for formStep change triggered");
    window.history.pushState({ formStep }, '', window.location.href);
  }, [formStep]);

  useEffect(() => {
    const handleInteraction = () => {
      setShowReCaptcha(true);
    };

    // Set up the event listeners for mouse movements, key presses, and clicks
    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    window.addEventListener('click', handleInteraction);
    window.addEventListener('scroll', handleInteraction);

    return () => {
      // Clean up the event listeners
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, []);

  return (
    <>
      {showReCaptcha ? (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY3}>
          <Form
            service={service}
            formType={formType}
            multiPart={multiPart}
            setFormStep={setFormStep}
            formStep={formStep}
            showHeading={showHeading}
            showHeader={showHeader}
            showFooter={showFooter}
          />
        </GoogleReCaptchaProvider>
      ) : (
        <Form
          service={service}
          formType={formType}
          multiPart={multiPart}
          setFormStep={setFormStep}
          formStep={formStep}
          showHeading={showHeading}
          showHeader={showHeader}
          showFooter={showFooter}
        />
      )}
    </>
  );
}

MultiForm.propTypes = {
  backgroundColor: PropTypes.string,
  formType: PropTypes.string,
  service: PropTypes.string,
  multiPart: PropTypes.bool,
  showHeading: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
};
