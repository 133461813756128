import { lazy } from "react";

const SnaggingTermsAndConditions = lazy(() =>
  import("../forms/MultiForm/services/Snagging/textContent/TermsAndConditions")
);
const SnaggingPrivacyPolicy = lazy(() =>
  import("../forms/MultiForm/services/Snagging/textContent/PrivacyPolicy")
);

export const legalContentSchema = {
  Snagging: {
    "Terms and Conditions": {
      Content: SnaggingTermsAndConditions,
      Title: "New Build Inspections Terms & Conditions",
    },
    "Privacy Policy": {
      Content: SnaggingPrivacyPolicy,
      Title: "New Build Inspections Privacy Policy",
    },
    // other documents...
  },
  BestEA: {
    "Terms and Conditions": {
      Content: SnaggingTermsAndConditions,
      Title: "New Build Inspections Terms & Conditions",
    },
    "Privacy Policy": {
      Content: SnaggingPrivacyPolicy,
      Title: "New Build Inspections Privacy Policy",
    },
    // other documents...
  },
  BuildingSurvey2: {
    "Termszz and Conditions": {
      Content: SnaggingTermsAndConditions,
      Title: "Local Surveyors Direct Terms & Conditions",
    },
    "Privacy Policy": {
      Content: SnaggingPrivacyPolicy,
      Title: "Local Surveyors Direct Privacy Policy",
    },
    // other documents...
  },
  // other services...
};
