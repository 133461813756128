// initializeGA4.js
/* global gtag, dataLayer */
const initializeGA4 = (callback) => {
  if (window.dataLayer && Array.isArray(window.dataLayer)) {
    if (callback) callback();
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'G-X2LEWPWZEG', {
    'linker': {
      'domains': ['hoa.org.uk', 'hoa-forms.web.app']
    }
  });

  if (callback) callback();
};

export default initializeGA4;
