import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "./firebase"; // This is your Firebase Firestore initialization.
import { getAuth, signInAnonymously } from "firebase/auth";
import { deviceType, osName } from "react-device-detect";

// Function to keep only specific fields: email, phone, postcode
function filterData(data) {
  return {
    email: data.email,
    phone: data.phone,
    postcode: data.postcode,
  };
}

export const postLeadToFirestore = async (data, service) => {
  const auth = getAuth();
  if (!auth.currentUser) {
    try {
      await signInAnonymously(auth);
      //console.log("User signed in anonymously");
    } catch (error) {
      console.error("Error signing in anonymously:", error);
      throw error; // If you can't authenticate, then you may want to stop further operations.
    }
  }

  const sessionId = sessionStorage.getItem("sessionId");
  const hashedIp = sessionStorage.getItem("hashedIp");

  // Filter the data to keep only specific fields and add the service
  const filteredData = {
    ...filterData(data),
    service,
    sessionId,
    deviceType,
    osName,
    hashedIp,
    timestamp: Timestamp.fromDate(new Date()),
  };

  try {
    const docRef = await addDoc(collection(db, "leads"), filteredData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id; // If you want to return the document ID
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};
