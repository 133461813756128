import { useState } from "react";

function useDisabledButton() {
  const [isDisabled, setDisabled] = useState(false);

  const disableButton = () => {
    setDisabled(true);
  };

  const enableButton = () => {
    setDisabled(false);
  };

  return { isDisabled, disableButton, enableButton };
}

export default useDisabledButton;
