import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFormSchema } from "../config/formValidationSchema";

const useCustomForm = (service, formType, formStep) => {
  const schema = getFormSchema(service, formType, formStep);
  
  //console.log('Service:', service);
  //console.log('Schema:', schema);
  //console.log('Schema fields:', Object.keys(schema.fields));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  return { register, handleSubmit, control, errors, isSubmitting };
};

export default useCustomForm;
