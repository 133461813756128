/**
 * Split fullName into firstName and lastName
 * 
 * @param {string} fullName The full name to be split
 * @return {Object} An object containing firstName and lastName properties
 */
export const splitFullName = (fullName) => {
    if (!fullName) {
        return null; 
    }

    const parts = fullName.trim().split(' ');

    if (parts.length === 1) {
        return {
            firstName: '-',
            lastName: parts[0]
        };
    } else {
        return {
            firstName: parts[0],
            lastName: parts.slice(1).join(' ')
        };
    }
};
