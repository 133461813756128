// useHandleErrors.js
import { useEffect } from "react";

const useHandleErrors = (errors) => {
  useEffect(() => {
    if (errors) {
      const windowWidth = window.innerWidth;
      const errorsvalues = Object.values(errors);

      //if (errorsvalues.length > 0) {
        if (errorsvalues.length > 0 && errorsvalues[0].ref) { // Add this check

        const element = document.getElementById(errorsvalues[0].ref.name);
        let yOffset = -60; // mobile
        if (windowWidth > 1279) {
          yOffset = -120;
        }
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
        
      }
    }
  }, [errors]);
};

export default useHandleErrors;
