import zapierServiceConfig from '../config/zapierUrls';

export const zapierPost = async (zapierPayload, hookUrl) => {
  //console.log(zapierPayload);
  //console.log(hookUrl);

  // Add current URL to the payload
  zapierPayload.currentUrl = window.location.href;

  const stringifiedPayload = JSON.stringify(zapierPayload);
  //console.log(stringifiedPayload);

  try {
    const response = await fetch(hookUrl, {
      method: "POST",
      body: stringifiedPayload, // browser will automatically set content-type header
    });
    //eslint-disable-next-line
    const data = await response.text();
    //console.log(response.status);
    
    //console.log(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postToZapier = async (data, abVersion, service) => {
  const sessionId = sessionStorage.getItem('sessionId');
  const updatedData = { ...data, ABVersion: abVersion, service: service, sessionId: sessionId  };
  
  // Here is where the service parameter is used to get the URL
  const zapierHookUrl = zapierServiceConfig[service];
  
  return zapierPost(updatedData, zapierHookUrl);
};
