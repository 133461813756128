/**
 * Function to post data to the Best Estate Agent service and redirect upon success.
 * 
 * @param {Object} data - The data to be posted.
 * @param {string} data.postcode - The postcode of the property.
 * @param {string} data.propertyType - The type of the property.
 * @param {string} data.propertySize - The number of bedrooms in the property.
 * @param {string} data.propertyValue - The value of the property.
 */
export const besteaPost = async (data) => {
  // Create a new object with the required data format for the POST request
  const newData = {
    postcode: data.postcode,
    property_type: data.propertyType,
    bedrooms: data.propertySize,
    price: data.propertyValue,
    appointWhen: data.appointWhen,
  };

  // URL to post the data to
  const postDataUrl = "https://hoa.org.uk/services/best-estate-agent-data/";

  // Create a form element
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = postDataUrl;
  form.style.display = 'none'; // Hide the form from view

  // Add the data as hidden input fields
  Object.entries(newData).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  // Append the form to the body
  document.body.appendChild(form);

  // Submit the form
  form.submit();
};






/**export const besteaPost = async (data) => {
  const newData = {
    postcode: data.postcode,
    property_type: data.propertyType,
    bedrooms: data.propertySize,
    price: data.propertyValueAny,
  };

  const hostname = window.location.hostname;
  let generateTokenUrl;
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    // If localhost, use the localhost URL
    generateTokenUrl = "http://localhost:3005/server.js";
    //generateTokenUrl = "https://us-central1-react-wordpress-32f46.cloudfunctions.net/generateToken";
  } else {
    // If not localhost, use the production/staging URL
    generateTokenUrl =
      "https://us-central1-react-wordpress-32f46.cloudfunctions.net/generateToken";
  }

  const response = await fetch(generateTokenUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(newData),
  });

  if (response.ok) {
    const responseData = await response.json();

    let redirectUrl;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      redirectUrl = `http://localhost:3000/?service=BestEstateAgent&token=${responseData.token}`;
      //redirectUrl = `https://hoa-forms-staging.web.app/?form=BestEstateAgent&token=${responseData.token}`;
    } else {
      //redirectUrl = `https://react-wordpress-32f46.web.app/?service=BestEstateAgent&token=${responseData.token}`;
      redirectUrl = `https://hoa.org.uk/services/best-estate-agent-data/?token=${responseData.token}`;
    }

    // Redirect to App2
    window.location.href = redirectUrl;
  } else {
    console.error("Failed to fetch from server");
  }
};**/
