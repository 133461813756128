export const ea4mePost = (data) => {
  const postUrl =
    "https://ea4me.hoa.org.uk/compare/?act=cmp&hidLS=1012&pc=" +
    data.postcode +
    "&pt=" +
    data.propertyType +
    "&beds=" +
    data.propertySize +
    "&aa=" +
    data.appointWhen +
    "&ev=" +
    data.propertyValue;
  console.log(postUrl);
  window.open(postUrl, "_self");
};


export const ea4mePost2024 = (data) => {
  const postUrl =
    "https://stg-hoaorguk-staging.kinsta.cloud/homeco/?postcode=" +
    data.postcode +
    "&type=" +
    data.propertyType +
    "&beds=" +
    data.propertySize +
    "&aa=" +
    data.appointWhen +
    "&estimated_price=" +
    data.propertyValue;
  console.log(postUrl);
  window.open(postUrl, "_self");
};
