import React from "react";
import PropTypes from 'prop-types';

// MUI
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

export default function FormDivider({ label }) {

  // Declare the PropTypes for each prop
  FormDivider.propTypes = {
    label: PropTypes.string,
  };

  return (
    <>
      <Divider
        textAlign="left"
        sx={{
          paddingTop: "18px",
          paddingBottom: "12px",
          fontWeight: "500 !important",
          color: "#D80080",
          "&::before": { borderTop: "thin solid #D80080", top: '0%' },
          "&::after": { borderTop: "thin solid #D80080",  top: '0%' },
        }}
      >
        <Typography sx={{fontSize: "1.2rem !important", fontWeight: "500 !important",color: "#D80080 !important", mb: '0 !important'}}>{label}</Typography>
      </Divider>

    </>
  );
}
