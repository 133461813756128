


export const handleSaveOnBlur = async () => {
  //set an expiry date for the local storage item
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * 60 * 60 * 24 * 7; // 7 days
  const expireDate = new Date(expireTime);
  localStorage.setItem("expires", expireDate.toUTCString());

  //this.setState({inputValue: e.target.value});
  //console.log("saving..." + name + " + " + value);
  //localStorage.setItem(name, value); // write the current data to local storage
  //console.log(JSON.stringify(getValues()))
  //saveToFirestore(name, value)
  
};

/*
const formatPhoneNumber = async (phoneNumber) => {
  console.log("formatting phone number");
  console.log(phoneNumber);

  // Remove spaces from the phone number
  phoneNumber = phoneNumber.replace(/\s/g, "");

  // Check that the phone number has exactly 11 digits and only contains numbers 0-9
  const phoneRegex = /^[0-9]{11}$/;
  if (!phoneRegex.test(phoneNumber)) {
    console.log("Invalid phone number format.");
    return;
  }

  // If the first digit is 0, replace it with +44
  if (phoneNumber.charAt(0) === "0") {
    phoneNumber = "+44" + phoneNumber.slice(1);
  }

  // Encode the phone number and call validatePhoneNumber if it passes the checks
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  if (encodedPhoneNumber.length === 15) {
    console.log("Phone number encoding successful.... sent to validatePhoneNumber")
    const validatedPhoneNumber = await validatePhoneNumber(encodedPhoneNumber);
    console.log(validatedPhoneNumber);
    return validatedPhoneNumber
    // Set state with the value of validatedPhoneNumber.valid
   
  } else {
    console.log("Phone number encoding failed.");
  }
};
*/

/*
async function validatePhoneNumber(encodedPhoneNumber) {
  if (!encodedPhoneNumber) {
    console.log("Phone number is not defined.");
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("apikey", "XeZWwFOqXDMEa41LlX2HD3SqpEKGV6o4");

  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: myHeaders,
  };

  try {
    const response = await fetch(`https://api.apilayer.com/number_verification/validate?number=${encodedPhoneNumber}`, requestOptions);
    const result = await response.text();
    const parsedResult = JSON.parse(result); // Parse the response string into an object
    console.log(parsedResult.valid); // Log the valid status
    console.log(parsedResult.carrier); // Log the carrier property of the object
    return parsedResult.valid;
   
  } catch (error) {
    console.log('error', error);
  }
}
*/
// Call the async function

//Rounds a number to nearest integer and add 1000s comma separator
export const formatNumber = (num) => {
  const roundedNum = Math.round(num);
  const localeNum = roundedNum.toLocaleString();
  const formattedNum = "£" + localeNum;
  return formattedNum;
};

// Exracts the first part of a UK postcode by removing last three characters and all whitespace
export const getPostcodeFirstPart = (postcode) => {
  // Check if the postcode matches the pattern of a full UK postcode
  const fullPostcodePattern = /^[A-Z]{1,2}[0-9R][0-9A-Z]?[ ]?[0-9][A-Z]{2}$/i;
  
  if (fullPostcodePattern.test(postcode)) {
    const postcodeFirstPart = postcode
      .substring(0, postcode.length - 3)
      .replace(/\s+/g, "");
    return postcodeFirstPart;
  }
  
  // If it's not a full postcode, assume it's the outward code and return as is
  return postcode;
};


/**
 * Takes in form data, postcode first part, and an sdnid, and returns a modified
 * object with the form data in a specific format for the LSD API payload.
 * The function replaces certain keys in the data with different key names,
 * adds a district, sdnid, and role to the data, and returns the modified data as a
 * new object.
 * @param {Object} data - The survey data to be modified.
 * @param {string} postcodeFirstPart - The first part of the postcode to be added to the data.
 * @param {string} sdnid - The sdnid to be added to the data.
 * @returns {Object} - A new object with the modified survey data in the required format for a building survey payload.
 */
export const prepLSDData = (data, postcodeFirstPart, sdnid) => {
  const keyMap = {
    firstName: "first",
    lastName: "last",
    phone: "telephone",
    propertyValue: "value",
    propertySize: "bedrooms",
  };

  const modifiedObject = {};

  Object.keys(data).forEach((key) => {
    modifiedObject[keyMap[key] || key] = data[key];
  });

  modifiedObject["district"] = postcodeFirstPart;
  modifiedObject["sdnid"] = sdnid;
  modifiedObject["role"] = "1";

  const LSDPayload = { servicesQuery: modifiedObject };

  return LSDPayload;
};

export const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: "auto",
  });

export const handleErrors = (
  errors,
  setMainErrorMessage,
  setShowErrorAlert
) => {
  if (errors) {
    const windowWidth = window.innerWidth;
    const errorsvalues = Object.values(errors);

    if (errorsvalues.length > 0) {
      //////const errorElement = errorsvalues[0].ref.name;
      //console.log(errorElement);
      const element = document.getElementById(errorsvalues[0].ref.name);
      //setFocus(element)
      let yOffset = -60; // mobile
      if (windowWidth > 1279) {
        yOffset = -120;
      }

      if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      }
      setMainErrorMessage(errorsvalues[0].message);
      setShowErrorAlert(true);
    }
  }
};

export const handleErrorsNew = (
  errors,
 
  setShowErrorAlert
) => {
  if (errors) {
    const windowWidth = window.innerWidth;
    const errorsvalues = Object.values(errors);

    if (errorsvalues.length > 0) {
      //////const errorElement = errorsvalues[0].ref.name;
      //console.log(errorElement);
      const element = document.getElementById(errorsvalues[0].ref.name);
      //setFocus(element)
      let yOffset = -60; // mobile
      if (windowWidth > 1279) {
        yOffset = -120;
      }
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      
      setShowErrorAlert(true);
    }
  }
};
