import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TermsDialog from "./TermsDialog";

function DialogLink({ service }) {

    //console.log('Service:', service);
  // State and handlers for dialog
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");

  const handleTermsClick = (e) => {
    e.preventDefault();
    setOpen(true);
    setDialogType("Terms and Conditions");
  };

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setOpen(true);
    setDialogType("Privacy Policy");
  };

  const handleClose = () => {
    setOpen(false);
  };

  // After the component has rendered, attach an event listener to the terms and conditions link
  useEffect(() => {
    const termsLink = document.getElementById('termsLink');
    const privacyPolicyLink = document.getElementById('privacyPolicyLink');
    if (termsLink) {
      termsLink.addEventListener('click', handleTermsClick);
    }
    if (privacyPolicyLink) {
      privacyPolicyLink.addEventListener('click', handlePrivacyClick);
    }

    // Cleanup function
    return () => {
      if (termsLink) {
        termsLink.removeEventListener('click', handleTermsClick);
      }
      if (privacyPolicyLink) {
        privacyPolicyLink.removeEventListener('click', handlePrivacyClick);
      }
    };
  }, [handleTermsClick, handlePrivacyClick]);

  return (
    <>
      {dialogType !== "" && (
        <TermsDialog open={open} handleClose={handleClose} service={service} dialogType={dialogType} />
      )}
    </>
  );
}

DialogLink.propTypes = {
  service: PropTypes.string,
};

export default DialogLink;
