import { postToZapier } from "../hooks/useZapier";
import { ea4mePost } from "./onSubmitServices/ea4mePost";
import { ea4mePost2024 } from "./onSubmitServices/ea4mePost";
import { besteaPost } from "./onSubmitServices/besteaPost";
import { lsdPost } from "./onSubmitServices/lsdPost";
import { postLeadToFirestore } from "./postLeadToFirestore";

import initializeGA4 from "../config/initializeGA4";
//import triggerGAEvent from "./triggerGAEvent";

const onSubmitHelper = async (
  data,
  startLoading,
  stopLoading,
  formType,
  formStep,
  setFormData,
  setFormStep,
  service,
  formData,
  validateRecaptcha,
  disableButton,
  setLSDResponse,
  updateURL
) => {
  //initializeGA4();
  startLoading();

  try {
    if (formType === "FormType1") {
      // single part form
      const validationPassed = await validateRecaptcha();
      if (validationPassed) {
        updateURL();
        const event = new Event("reactFormSubmitted");
        window.dispatchEvent(event);
      } else {
        disableButton();
        stopLoading();
        return; // Return early if not validated
      }

      if (
        service === "Snagging" ||
        service === "SpecialistLending" ||
        service === "LeaseExpert" ||
        service === "PropertyDisputes"
      ) {
        await postToZapier(data, "Single", service);
        setFormStep(1);
        window.scrollTo(0, 0);
      } else if (
        service === "Architect" ||
        service === "ExpertWitness" ||
        service === "PartyWall" ||
        service === "Planning" ||
        service === "RightToLight" ||
        service === "AsbestosSurvey" ||
        service === "FloodRiskSurvey" ||
        service === "StructuralCalculations" ||
        service === "StructuralInspections" ||
        service === "BuildingSurvey" ||
        service === "BuildingSurveyRemarketing" ||
        service === "EPC" ||
        service === "HomebuyerSurvey" ||
        service === "ValuationSurvey"
      ) {
        //if (typeof window.gtag === "function") {
        //  triggerGAEvent(service);
        //}

        if (data.postcode === "TE57") {
          service = "Test";
          await postLeadToFirestore(data, service);
        } else {
          initializeGA4(() => {
            if (window.dataLayer) {
              window.dataLayer.push({
                event: `new_lead_${service.toLowerCase()}`,
              });
            }
          });

          await lsdPost(data, service, setLSDResponse);
          await postToZapier(data, "Single", service);
          await postLeadToFirestore(data, service);
        }

        setFormStep(1);
      } else if (service === "EA4Me") {
        ea4mePost(data);
        disableButton();
      } else if (service === "EA4Me2024") {
        ea4mePost2024(data);
        disableButton();
      } else if (service === "BestEA") {
        await besteaPost(data);
        disableButton();
      }
    }

    //  multi part form
    else if (formType === "FormType2") {
      if (formStep === 0) {
        setFormData({ ...data });
        setFormStep(1);
      } else if (formStep === 1) {
        const validationPassed = await validateRecaptcha();
        if (validationPassed) {
          updateURL(); // <-- Add this line
        } else {
          disableButton();
          stopLoading();
          return; // Return early if not validated
        }

        if (
          service === "Snagging" ||
          service === "SpecialistLending" ||
          service === "LeaseExpert"
        ) {
          await postToZapier({ ...formData, ...data }, "MultiPart", service);
          setFormStep(2);
        } else if (
          service === "EPC" ||
          service === "BuildingSurvey" ||
          service === "BuildingSurveyRemarketing"
        ) {
          await lsdPost({ ...formData, ...data }, service, setLSDResponse);
          await postToZapier({ ...formData, ...data }, "MultiPart", service);
          setFormStep(2);
        }
      }
    }
  } catch (error) {
    console.error(error);
    // You may want to display the error to the user
  } finally {
    stopLoading();
  }
};

export default onSubmitHelper;
