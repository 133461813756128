import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

export const useRecaptchaValidation = (action) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validateRecaptcha = async () => {
    const token = await executeRecaptcha(action);
    //const response = await axios.post('https://us-central1-react-wordpress-32f46.cloudfunctions.net/recaptchaVerify', { token });
    const response = await axios.post('https://us-central1-hoa-forms.cloudfunctions.net/recaptchaVerify', { token });
    //console.log(response.data);
    return response.data.score >= 0.5;
  };

  return validateRecaptcha;
};
export default useRecaptchaValidation;
