import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


import { getFirestore, getDoc, collection, doc, setDoc } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAasXgW-Z1n8T7Ly1zqZFLP_zDWP6JK-a0",
  authDomain: "hoa-forms.firebaseapp.com",
  projectId: "hoa-forms",
  storageBucket: "hoa-forms.appspot.com",
  messagingSenderId: "31285431323",
  appId: "1:31285431323:web:a5dd9a641a223e566d7cbe"
};

/*const firebaseConfig = {
  apiKey: "AIzaSyDM8RBUEaAaQAiFBl0fzNOCEUS6NMwpfSo",
  authDomain: "hoa-forms-staging.firebaseapp.com",
  projectId: "hoa-forms-staging",
  storageBucket: "hoa-forms-staging.appspot.com",
  messagingSenderId: "288202915895",
  appId: "1:288202915895:web:eb6a13e833db0e3f51bfb2"
};*/


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const newSession = (newSessionID, ipAddress, timestamp, embedReact) => {
  const docRef = doc(collection(db, "Completions"), newSessionID); // Set document ID to newSessionID
  setDoc(docRef, {
    ipAddress: ipAddress,
    timestamp: timestamp,
    form: embedReact,
  })
  .then(() => {
    //console.log('Completion document successfully written!');
  })
  .catch((error) => {
    console.error('Error writing completion document: ', error);
  });
};
export { newSession };


const saveToFirestore = (name, value) => {
  //console.log('saveToFirestore: ' + name + ' ' + value)
  const sessionID = sessionStorage.getItem('sessionID');

  const completionRef = doc(db, 'Completions', sessionID);
  setDoc(completionRef, {
    [name]: value
  }, { merge: true })
  .then(() => {
    //console.log('Completion document successfully updated!');
  })
  .catch((error) => {
    console.error('Error updating completion document: ', error);
  });
}
export { saveToFirestore };

const saveEmbedToFirestore = (embedReact, status) => {
  const sessionID = sessionStorage.getItem('sessionID');

  if (sessionID) {
    const completionRef = doc(db, 'Completions', sessionID);

    // Get the current value of the embedReact field
    getDoc(completionRef).then((doc) => {
      const data = doc.data();
      const currentValue = data ? data[embedReact] : null;

      // Only update the field if the current value is not 'completed'
      if (currentValue !== 'completed') {
        setDoc(completionRef, {
          [embedReact]: status
        }, { merge: true })
        .then(() => {
          //console.log('Completion document successfully updated!');
        })
        // eslint-disable-next-line
        .catch((error) => {
          //console.error('Error updating completion document: ', error);
        });
      }
    });
  }
};

export { saveEmbedToFirestore };




const saveLSDResponseToFirestore = (LSDResponse) => {

  //console.log(LSDResponse)
  const sessionID = sessionStorage.getItem('sessionID');
  const completionRef = doc(db, 'Completions', sessionID);
  setDoc(completionRef, { LSDResponse }, { merge: true })
    .then(() => {
      //console.log('Completion document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating completion document: ', error);
    });
}

export { saveLSDResponseToFirestore };





