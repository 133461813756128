import axios from "axios";
import { getLSDsdnid } from "../../config/getLSDsdnid";
import {
  getPostcodeFirstPart,
  prepLSDData,
} from "../../utils/miscFunctions";

import { splitFullName } from "../../utils/splitFullName";

export const lsdPost = async (data, service, setLSDResponse) => {
  console.log("LSDPOST");
  try {
    if (data.fullName) {
      const { firstName, lastName } = splitFullName(data.fullName);
      data.firstName = firstName;
      data.lastName = lastName;
      delete data.fullName;
    }

    const postcodeFirstPart = getPostcodeFirstPart(data.postcode);
    var sdnid = getLSDsdnid(service);
    
    // If the service is RightToLight and the user selects the 'Daylight and Sunlight' option, change the sdnid to '411
    if (sdnid === 959170 && data.rightToLightSelectMenu === "411") {
      sdnid = 411;
    }

    // AsbestosSurvey - Commercial or Residential
    if (sdnid === 339 && data.asbestosSurveySelectMenu === "810826") {
      sdnid = 810826;
      console.log("AsbestosSurvey - Commercial");
    }


    const LSDSurveyPayload = prepLSDData(data, postcodeFirstPart, sdnid);
console.log(sdnid);
    // Use this line if you are calling the function directly
    const functionEndpoint = 'https://us-central1-hoa-forms.cloudfunctions.net/lsdAPI';

    // If you have enabled CORS in your Firebase Function, you can use the direct URL
    // Otherwise, if you're rewriting the URL through Firebase Hosting, use the relative path
    const response = await axios.post(functionEndpoint, {
      service: service,
      payload: LSDSurveyPayload
    });

    setLSDResponse(response.data.servicesQueryResult);

  } catch (err) {
    console.error(err);
    alert('An error occurred while posting data');
  }
};
