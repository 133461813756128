import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { legalContentSchema } from "../../config/legalContentSchema";

function TermsDialog({ open, handleClose, service, dialogType }) {
  const { Content: ContentComponent, Title: title } =
    legalContentSchema[service][dialogType];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ overflowWrap: "break-word" }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <ContentComponent />
          </Suspense>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

TermsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  service: PropTypes.string,
  dialogType: PropTypes.string.isRequired,
};

export default TermsDialog;
