export default {
    SpecialistLending: "https://hooks.zapier.com/hooks/catch/10361915/3mfe1sw/",
    Snagging: "https://hooks.zapier.com/hooks/catch/10361915/3mferro/",
    LeaseExpert: "https://hooks.zapier.com/hooks/catch/10361915/3mfi98w/",
    PropertyDisputes: "https://hooks.zapier.com/hooks/catch/10361915/24ik3z0/",
    Architect: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    BuildingSurvey: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    BuildingSurveyRemarketing: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    EPC: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    ExpertWitness: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    HomebuyerSurvey: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    PartyWall: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    Planning: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    RightToLight: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    StructuralCalculations: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    StructuralInspections: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    ValuationSurvey: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    AsbestosSurvey: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
    FloodRiskSurvey: "https://hooks.zapier.com/hooks/catch/10361915/3mg3iny/",
  };  