const serviceMap = {
  RightToLight: 959170,
  BuildingSurvey: 11,
  BuildingSurveyRemarketing: 11,
  EPC: 335,
  HomebuyerSurvey: 340,
  Architect: 13,
  ExpertWitness: 396,
  PartyWall: 329,
  Planning: 394,
  StructuralCalculations: 430,
  StructuralInspections: 334,
  ValuationSurvey: 341,
  AsbestosSurvey: 339,
  FloodRiskSurvey: 406,
};

export function getLSDsdnid(service) {
  return serviceMap[service];
}
