// External packages
import React, { useState, useContext, useEffect, Suspense } from "react";
import PropTypes from "prop-types";

// Components
import SubmitButton from "../../components/submit/SubmitButton";
import Spinner from "../../components/spinner/Spinner";
import FormStepper from "../../components/stepper/FormStepper";
import FormDivider from "../../components/divider/FormDivider2";
// Steps
import { FORMS } from "../../config/formsSchema";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Hooks
import useRecaptchaValidation from "../../hooks/useRecaptchaValidation";

import useHandleErrors from "../../hooks/useHandleErrors";
import useCustomForm from "../../hooks/useCustomForm";
import useLoading from "../../hooks/useLoading";
import useDisabledButton from "../../hooks/useDisabledButton";

import { CompactContext } from "../../App";
// Utils

import onSubmitHelper from "../../utils/onSubmitHelper";

export default function Form({
  service,
  multiPart,
  setFormStep,
  formStep,
  showHeading,
  showHeader,
  showFooter,
}) {
  const [formData, setFormData] = useState({});

  // Custom hooks

  const { showSpinner, startLoading, stopLoading } = useLoading();
  const { isDisabled, disableButton } = useDisabledButton();
  const [formType, setFormType] = useState("FormType1");
  const { register, handleSubmit, control, errors, isSubmitting } =
    useCustomForm(service, formType, formStep);
  useHandleErrors(errors);
  const [lsdResponse, setLSDResponse] = useState(null);

  //console.log(lsdResponse);

  const validateRecaptcha = useRecaptchaValidation(service);
  //eslint-disable-next-line
  const compact = useContext(CompactContext);

  const steps = FORMS[service][formType].map((step) =>
    step.components.map((c) => c.label)
  );


  const updateURL = () => {
    const currentURL = window.location.href;
    const separator = currentURL.includes('?') ? '&' : '?';
    const newURL = `${currentURL}${separator}submitted=true`;
    window.history.pushState({ path: newURL, formStep }, '', newURL);
}


  const onSubmit = async (data) => {

  

    await onSubmitHelper(
      data,
      startLoading,
      stopLoading,
      formType,
      formStep,
      setFormData,
      setFormStep,
      service,
      formData,
      validateRecaptcha,
      disableButton,
      setLSDResponse,
      updateURL
    );
  };

  

  //console.log("multiPart: ", multiPart);

  let currentStep = FORMS[service][formType].find(
    (step) => step.formStep === formStep
  );

  useEffect(() => {
    if (multiPart) {
      setFormType("FormType2");
    } else {
      setFormType("FormType1");
    }
  }, [multiPart]);

  useEffect(() => {
    // Get postcode from URL and save to sessionStorage

    const urlParams = new URLSearchParams(window.location.search);
    const postcode = urlParams.get("postcode");
    //console.log(postcode);

    if (postcode) {
      // Save postcode to sessionStorage
      sessionStorage.setItem("postcode", postcode);
    }
  }, []);

  const submitButton = (
    <SubmitButton
      buttonText={currentStep.buttonText}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      validateRecaptcha={validateRecaptcha}
      disabled={isDisabled}
      isSubmitting={isSubmitting}
      buttonFooterText={currentStep.buttonFooterText}
      service={service}
    />
  );

  return (
    <div style={{ width: "100%" }}>
      <form id={service} onSubmit={handleSubmit(onSubmit)}>
        <Suspense fallback={null}>
          {(showHeading || showHeader) && (
            <Box sx={{ mb: 1.5 }}>
              {showHeading && (
                <Typography
                  sx={{
                    color: "#4F145B !important",
                    fontSize: "1.3rem !important",
                    fontWeight: "500 !important",
                    mb: "8px !important",
                  }}
                >
                  {showHeading && currentStep.heading}
                </Typography>
              )}
              {showHeader && <Typography>{currentStep.header}</Typography>}
            </Box>
          )}

          {multiPart && <FormStepper formStep={formStep} steps={steps} />}
          {currentStep.components.map((step, index) => {
            const CurrentComponent = step.component;
            return (
              <React.Fragment key={index}>
                {!(compact || multiPart || step.label === undefined) && (
                  <FormDivider label={step.label} />
                )}


{step.sectionText && <Typography paragraph={true}>{step.sectionText}</Typography>}


                <CurrentComponent
                  control={control}
                  register={register}
                  errors={errors}
                  label={step.label}
                  lsdResponse={lsdResponse}
                  service={service}
                />
              </React.Fragment>
            );
          })}
          {currentStep.buttonText && (showSpinner ? <Spinner /> : submitButton)}

          {showFooter && currentStep.footer && (
            <Box sx={{ mt: 4 }}>
              <currentStep.footer />
            </Box>
          )}
        </Suspense>
      </form>
    </div>
  );
}

// PropTypes
Form.propTypes = {
  service: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  multiPart: PropTypes.bool,
  setFormStep: PropTypes.func.isRequired,
  formStep: PropTypes.number.isRequired,
  showHeading: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
};
