import { lazy } from "react";

// Resuable Components
const PropertyDetails = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/PropertyDetails")
);
const LSDPropertyDetails = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/LSDPropertyDetails")
);
const LSDPropertyDetailsSize = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/LSDPropertyDetailsSize")
);
const YourDetails = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/YourDetails")
);
const YourDetailsCombinedName = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/YourDetailsCombinedName")
);
const Checkboxes = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/Checkboxes")
);
const Newsletter = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/Newsletter")
);
//const Postcode = lazy(() =>
//  import("../forms/MultiForm/groupedFormComponents/Postcode")
//);
const Outcode = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/Outcode")
);
const OtherInformation = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/OtherInformation")
);
const EA4Me = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/EA4Me")
);
const BestEA = lazy(() =>
  import("../forms/MultiForm/groupedFormComponents/BestEA")
);

// Service Sepcific Components

const LSDResults = lazy(() =>
  import("../forms/MultiForm/services/LSD/formComponents/LSDResults")
);

const SnaggingThankYou = lazy(() =>
  import("../forms/MultiForm/services/Snagging/formComponents/ThankYou")
);

const SpecialistLendingThankYou = lazy(() =>
  import(
    "../forms/MultiForm/services/SpecialistLending/formComponents/ThankYou"
  )
);

const PropertyDisputesThankYou = lazy(() =>
  import(
    "../forms/MultiForm/services/PropertyDisputes/formComponents/ThankYou"
  )
);

const WhichServices = lazy(() =>
  import("../forms/MultiForm/services/LeaseExpert/formComponents/WhichServices")
);

const PropertyDisputesWhichServices = lazy(() =>
  import("../forms/MultiForm/services/PropertyDisputes/formComponents/PropertyDisputesWhichServices")
);


const RightToLightSelectMenu = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/RightToLight/formComponents/RightToLightServiceSelect"
  )
);

const AsbestosSurveySelectMenu = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/AsbestosSurvey/formComponents/AsbestosSurveyServiceSelect"
  )
);

const ContactHow = lazy(() =>
  import("../forms/MultiForm/services/LeaseExpert/formComponents/ContactHow")
);

// Headers & Footers

// EPC
const EPCStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/EPC/textContent/Step0Footer")
);
const EPCResultsFooter = lazy(() =>
  import("../forms/MultiForm/services/LSD/EPC/textContent/ResultsFooter")
);

// Building Survey
const BuildingSurveyStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/BuildingSurvey/textContent/Step0Footer"
  )
);
const BuildingSurveyResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/BuildingSurvey/textContent/ResultsFooter"
  )
);

// Building SurveyB
const BuildingSurveyStep0FooterB = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/BuildingSurvey/textContent/Step0FooterB"
  )
);

// Architect
const ArchitectStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/Architect/textContent/Step0Footer")
);
const ArchitectResultsFooter = lazy(() =>
  import("../forms/MultiForm/services/LSD/Architect/textContent/ResultsFooter")
);

// ExpertWitness
const ExpertWitnessStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/ExpertWitness/textContent/Step0Footer"
  )
);
const ExpertWitnessResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/ExpertWitness/textContent/ResultsFooter"
  )
);

// PartyWall
const PartyWallStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/PartyWall/textContent/Step0Footer")
);
const PartyWallResultsFooter = lazy(() =>
  import("../forms/MultiForm/services/LSD/PartyWall/textContent/ResultsFooter")
);

// AsbestosSurvey
const AsbestosSurveyStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/AsbestosSurvey/textContent/Step0Footer")
);
const AsbestosSurveyResultsFooter = lazy(() =>
  import("../forms/MultiForm/services/LSD/AsbestosSurvey/textContent/ResultsFooter")
);

// Planning
const PlanningStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/Planning/textContent/Step0Footer")
);
const PlanningResultsFooter = lazy(() =>
  import("../forms/MultiForm/services/LSD/Planning/textContent/ResultsFooter")
);

// StructuralCalculations
const StructuralCalculationsStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/StructuralCalculations/textContent/Step0Footer"
  )
);
const StructuralCalculationsResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/StructuralCalculations/textContent/ResultsFooter"
  )
);

// StructuralCalculations
const ValuationSurveyStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/ValuationSurvey/textContent/Step0Footer"
  )
);
const ValuationSurveyResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/ValuationSurvey/textContent/ResultsFooter"
  )
);

// StructuralInspections
const StructuralInspectionsStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/StructuralInspections/textContent/Step0Footer"
  )
);
const StructuralInspectionsResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/StructuralInspections/textContent/ResultsFooter"
  )
);

// RightToLight
const RightToLightStep0Footer = lazy(() =>
  import("../forms/MultiForm/services/LSD/RightToLight/textContent/Step0Footer")
);
const RightToLightResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/RightToLight/textContent/ResultsFooter"
  )
);

// FloodRiskSurvey
const FloodRiskSurveyStep0Footer = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/FloodRiskSurvey/textContent/Step0Footer"
  )
);
const FloodRiskSurveyResultsFooter = lazy(() =>
  import(
    "../forms/MultiForm/services/LSD/FloodRiskSurvey/textContent/ResultsFooter"
  )
);

// Lease Expert
const LeaseExpertThankYou = lazy(() =>
  import("../forms/MultiForm/services/LeaseExpert/formComponents/ThankYou")
);

export const FORMS = {
  // ------ EPC ------ //

  EPC: {
    FormType1: [
      {
        formStep: 0,
        heading: "EPC",
        header:
          "An Energy Performance Certificate (EPC) is needed to sell your home. Simply fill in the form to instantly receive EPC estimates and contact details from Domestic Energy Assessors or Home Inspectors working in your local area provided by our partners Local Surveyors Direct.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: EPCStep0Footer,
      },
      {
        formStep: 1,
        heading: "EPC",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: EPCResultsFooter,
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "EPC",
        header:
          "An Energy Performance Certificate (EPC) is needed to sell your home. Simply fill in the form to instantly receive EPC estimates and contact details from Domestic Energy Assessors or Home Inspectors working in your local area provided by our partners Local Surveyors Direct.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
        ],
        buttonText: "Continue",
        buttonFooterText: "",
        footer: EPCStep0Footer,
      },
      {
        formStep: 1,
        heading: "EPC",
        header:
          "An Energy Performance Certificate (EPC) is needed to sell your home. Simply fill in the form to instantly receive EPC estimates and contact details from Domestic Energy Assessors or Home Inspectors working in your local area provided by our partners Local Surveyors Direct.",
        components: [
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct.",
        footer: EPCStep0Footer,
      },
      {
        formStep: 2,
        heading: "EPC",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: EPCResultsFooter,
      },
    ],
  },

  // ------ Building Survey ------ //

  BuildingSurvey: {
    FormType1: [
      {
        formStep: 0,
        heading: "Building Survey",
        header:
          "In partnership with Local Surveyors Direct, get instant house survey quotes from Chartered Surveyors working in your area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
          {
            component: YourDetailsCombinedName,
            label: "Your Details",
            sectionText:
              "By completing this form, our partners Local Surveyors Direct share your details with firms providing the quotes, but absolutely no one else. We won't spam you.",
          },
          { component: Newsletter },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you.  This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0FooterB,
      },
      {
        formStep: 1,
        heading: "Building Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "Building Survey",
        header:
          "Simply fill in the form to receive Building Surveys (full structural surveys) estimates from our partners at Local Surveyors Direct who use chartered surveyors working in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
        ],
        buttonText: "Continue",
        buttonFooterText: "",
        footer: BuildingSurveyStep0FooterB,
      },
      {
        formStep: 1,
        heading: "Building Survey",
        header:
          "Simply fill in the form to receive Building Surveys (full structural surveys) estimates from our partners at Local Surveyors Direct who use chartered surveyors working in your local area.",
        components: [
          { component: YourDetailsCombinedName, label: "Your Details" },
          { component: Newsletter },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0Footer,
      },
      {
        formStep: 2,
        heading: "Building Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
  },

  // ------ Building Survey Remarketing ------ //

  BuildingSurveyRemarketing: {
    FormType1: [
      {
        formStep: 0,
        heading: "Building Survey",
        header:
          "In partnership with Local Surveyors Direct, get instant house survey quotes from Chartered Surveyors working in your area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
          {
            component: YourDetailsCombinedName,
            label: "Your Details",
            sectionText:
              "By completing this form, our partners Local Surveyors Direct share your details with firms providing the quotes, but absolutely no one else. We won't spam you.",
          },
          { component: Newsletter },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0FooterB,
      },
      {
        formStep: 1,
        heading: "Building Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "Building Survey",
        header:
          "Simply fill in the form to receive Building Surveys (full structural surveys) estimates from our partners at Local Surveyors Direct who use chartered surveyors working in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
        ],
        buttonText: "Continue",
        buttonFooterText: "",
        footer: BuildingSurveyStep0FooterB,
      },
      {
        formStep: 1,
        heading: "Building Survey",
        header:
          "Simply fill in the form to receive Building Surveys (full structural surveys) estimates from our partners at Local Surveyors Direct who use chartered surveyors working in your local area.",
        components: [
          { component: YourDetailsCombinedName, label: "Your Details" },
          { component: Newsletter },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0Footer,
      },
      {
        formStep: 2,
        heading: "Building Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
  },

  // ------ HomebuyerSurvey ------ //

  HomebuyerSurvey: {
    FormType1: [
      {
        formStep: 0,
        heading: "Homebuyer Survey",
        header:
          "Simply fill in the form to receive Homebuyer Survey (RICS Level 2 Survey) estimates from our partners at Local Surveyors Direct who use Chartered Surveyors working in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0Footer,
      },
      {
        formStep: 1,
        heading: "Homebuyer Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "Homebuyer Survey",
        header:
          "Simply fill in the form to receive Homebuyer Survey (RICS Level 2 Survey) estimates from our partners at Local Surveyors Direct who use Chartered Surveyors working in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
        ],
        buttonText: "Continue",
        buttonFooterText: "",
        footer: BuildingSurveyStep0Footer,
      },
      {
        formStep: 1,
        heading: "Homebuyer Survey",
        header:
          "Simply fill in the form to receive Level 2 Homebuyer Survey estimates from our partners at Local Surveyors Direct who use Chartered Surveyors working in your local area.",
        components: [
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: BuildingSurveyStep0Footer,
      },
      {
        formStep: 2,
        heading: "Homebuyer Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: BuildingSurveyResultsFooter,
      },
    ],
  },

  // ------ ValuationSurvey ------ //

  ValuationSurvey: {
    FormType1: [
      {
        formStep: 0,
        heading: "Valuation Survey",
        header:
          "Valuation surveys provide a property valuation often needed in cases of probate or divorce. You will also need a valuation survey if you want to sell or remortgage a Help to Buy or shared ownership home. Fill in the form to instantly receive quotes from Chartered Surveyors in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: ValuationSurveyStep0Footer,
      },
      {
        formStep: 1,
        heading: "Valuation Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: ValuationSurveyResultsFooter,
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "Valuation Survey",
        header:
          "Valuation surveys provide a property valuation often needed in cases of probate or divorce. You will also need a valuation survey if you want to sell or remortgage a Help to Buy or shared ownership home. Fill in the form to instantly receive quotes from Chartered Surveyors in your local area.",
        components: [
          { component: LSDPropertyDetails, label: "Property Details" },
        ],
        buttonText: "Continue",
        buttonFooterText: "",
        footer: ValuationSurveyStep0Footer,
      },
      {
        formStep: 1,
        heading: "Valuation Survey",
        header:
          "Valuation surveys provide a property valuation often needed in cases of probate or divorce. You will also need a valuation survey if you want to sell or remortgage a Help to Buy or shared ownership home. Fill in the form to instantly receive quotes from Chartered Surveyors in your local area.",
        components: [
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Get Estimates",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: ValuationSurveyStep0Footer,
      },
      {
        formStep: 2,
        heading: "Valuation Survey",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Quotes" }],
        footer: ValuationSurveyResultsFooter,
      },
    ],
  },

  // ------ StructuralInspections ------ //

  StructuralInspections: {
    FormType1: [
      {
        formStep: 0,
        heading: "Structural Inspections",
        header:
          "You may need a structural inspection if your property has signs of subsidence such as movement or cracking, a sagging roof or other structural issues. Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive multiple estimates for a Structural Inspection Survey on your property, from Structural Engineers and or Building Surveyors in your local area.",
        components: [
          { component: LSDPropertyDetailsSize, label: "Property Details" },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "The estimates will be displayed on the next screen, and will also be emailed to you. Please check that your email address is correct. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: StructuralInspectionsStep0Footer,
      },
      {
        formStep: 1,
        heading: "Structural Inspections",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: StructuralInspectionsResultsFooter,
      },
    ],
  },

  // ------ Right To Light ------ //

  RightToLight: {
    FormType1: [
      {
        formStep: 0,
        heading: "Right To Light",
        header:
          "Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive contact details of local professionals who can help you with a Right To Light enquiry.",
        components: [
          {
            label: "Your Details",
            sectionText:
              "By completing this form, our partners Local Surveyors Direct share your details with firms providing the quotes, but absolutely no one else. We won't spam you.",
            component: Outcode,
          },
          { component: YourDetailsCombinedName },
          {
            label: "Which Services Are You Interested In?",
            component: RightToLightSelectMenu,
          },
          { component: OtherInformation },
          { component: Newsletter },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: RightToLightStep0Footer,
      },
      {
        formStep: 1,
        heading: "Right To Light",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: RightToLightResultsFooter,
      },
    ],
  },

    // ------ Property Disputes ------ //

// ------ Lease Expert ------ //

PropertyDisputes: {
  FormType1: [
    {
      formStep: 0,
      heading: "Property Disputes",
        components: [
        { component: YourDetailsCombinedName, label: "Your Details" },
        
        {
          component: PropertyDisputesWhichServices,
          label: "What Do You Need Help With?",
        },{ component: OtherInformation },
        {
          component: ContactHow,
          label: "How Would You Like To Be Contacted?",
        },
        { component: Newsletter },
      ],
      buttonText: "Submit",
      buttonFooterText:
        "By completing this form your details are only shared with Bonallack and Bishop who are authorised and regulated by the Solicitors Regulation Authority. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
    },
    {
      formStep: 1,
      heading: "Property Disputes",
      components: [{ component: PropertyDisputesThankYou, label: "Thank You" }],
    },
  ],
},

  // ------ Architect ------ //

  Architect: {
    FormType1: [
      {
        formStep: 0,
        heading: "Find an Architect near me",
        header:
          "Simply fill in the form to instantly receive contact details of suppliers working on architectural projects in your area which include a mix of Architectural practices and Architectural Drawing Technologists. You can get in touch directly to discuss your project in more detail. Our tool is powered by partners Local Surveyors Direct.",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: OtherInformation },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: ArchitectStep0Footer,
      },
      {
        formStep: 1,
        heading: "Find an Architect near me",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: ArchitectResultsFooter,
      },
    ],
  },

  // ------ ExpertWitness ------ //

  ExpertWitness: {
    FormType1: [
      {
        formStep: 0,
        heading: "Expert Witness",
        header:
          "Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive contact details for Chartered Building Surveyors who can help you with an Expert Witness requirement (mediation, arbitration, dispute resolution, negligence investigation). You can contact them directly to discuss what level of service you require.",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: OtherInformation },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: ExpertWitnessStep0Footer,
      },
      {
        formStep: 1,
        heading: "Expert Witness",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: ExpertWitnessResultsFooter,
      },
    ],
  },

  // ------ PartyWall ------ //

  PartyWall: {
    FormType1: [
      {
        formStep: 0,
        heading: "Find a Party Wall Surveyor",
        header:
          "Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive contact details of Party Wall Surveyors in your local area who can help to address your Party Wall requirements. You can get in touch directly to compare price and service.",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: OtherInformation },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: PartyWallStep0Footer,
      },
      {
        formStep: 1,
        heading: "Find a Party Wall Surveyor",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: PartyWallResultsFooter,
      },
    ],
  },

  // ------ AsbestosSurvey ------ //

  AsbestosSurvey: {
    FormType1: [
      {
        formStep: 0,
        heading: "Asbestos Surveyor",
        header:
          "In partnership with Local Surveyors Direct, get instant estimates for an Asbestos Survey on your property.",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: AsbestosSurveySelectMenu },
          { component: OtherInformation },
          { component: YourDetailsCombinedName, label: "Your Details" },
          { component: Newsletter },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: AsbestosSurveyStep0Footer,
      },
      {
        formStep: 1,
        heading: "Asbestos Surveyor",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: AsbestosSurveyResultsFooter,
      },
    ],
  },

  // ------ Planning ------ //

  Planning: {
    FormType1: [
      {
        formStep: 0,
        heading: "Planning Consultants",
        header:
          "Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive contact details of local professionals who can help you with a Planning Permission Application or Planning Appeal (in the event that you have been refused planning permission and want to appeal the decision).",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: OtherInformation },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: PlanningStep0Footer,
      },
      {
        formStep: 1,
        heading: "Planning Consultants",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: PlanningResultsFooter,
      },
    ],
  },

    // ------ FloodRiskSurvey ------ //

    FloodRiskSurvey: {
      FormType1: [
        {
          formStep: 0,
          heading: "Flood Risk Survey",
          header:
            "In partnership with Local Surveyors Direct, get instant estimates from local surveyors who can carry out a Flood Risk Assessment report.",
          components: [
            { component: Outcode, label: "Property Details" },
            { component: OtherInformation },
            { component: YourDetailsCombinedName, label: "Your Details" },
            { component: Newsletter },
          ],
          buttonText: "Submit",
          buttonFooterText:
            "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
          footer: FloodRiskSurveyStep0Footer,
        },
        {
          formStep: 1,
          heading: "Flood Risk Survey",
          header:
            "Your results are below. You will also receive an email containing the same information.",
          components: [{ component: LSDResults, label: "Suppliers" }],
          footer: FloodRiskSurveyResultsFooter,
        },
      ],
    },

  // ------ StructuralCalculations ------ //

  StructuralCalculations: {
    FormType1: [
      {
        formStep: 0,
        heading: "Structural Calculations",
        header:
          "Renovations such as a loft or roof conversion or building an extension may require structural calculations. Simply fill in the form, powered by our partners Local Surveyors Direct, to instantly receive contact details for Structural Engineers in your local area who can help with your structural calculations and structural design needs.",
        components: [
          { component: Outcode, label: "Property Details" },
          { component: OtherInformation },
          { component: YourDetails, label: "Your Details" },
          { component: Checkboxes },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
        footer: StructuralCalculationsStep0Footer,
      },
      {
        formStep: 1,
        heading: "Structural Calculations",
        header:
          "Your results are below. You will also receive an email containing the same information.",
        components: [{ component: LSDResults, label: "Suppliers" }],
        footer: StructuralCalculationsResultsFooter,
      },
    ],
  },

  // ------ Snagging ------ //

  Snagging: {
    FormType1: [
      {
        formStep: 0,
        heading: "Snagging Survey",
        header:
          "Whether you're about to complete on a new build home or have moved in to find problems, we can connect you with an independent on-site snagging inspector today.",
        components: [
          { component: PropertyDetails, label: "Property Details" },
          { component: YourDetails, label: "Your Details" },
          { component: Newsletter },
        ],
        buttonText: "Get The Ball Rolling",
        buttonFooterText:
          "By completing this form your details are shared with New Build Inspections, but absolutely no one else. Note that any quotes or information provided are subject to New Build Inspections <a href='https://www.newbuildinspections.com/terms-and-conditions/' target='_blank'>Terms & Conditions</a> and <a href='https://www.newbuildinspections.com/privacy-policy/' target='_blank'>Privacy Policy</a>. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
      },
      {
        formStep: 1,
        components: [{ component: SnaggingThankYou, label: "Thank You" }],
      },
    ],
    FormType2: [
      {
        formStep: 0,
        heading: "Snagging Survey",
        header:
          "Whether you're about to complete on a new build home or have moved in to find problems, we can connect you with an independent on-site snagging inspector today.",
        components: [{ component: PropertyDetails, label: "Property Details" }],
        buttonText: "Continue",
        buttonFooterText: "",
      },
      {
        formStep: 1,
        heading: "Snagging Survey",
        header:
          "Whether you're about to complete on a new build home or have moved in to find problems, we can connect you with an independent on-site snagging inspector today.",
        components: [{ component: YourDetails, label: "Your Details" }],
        buttonText: "Get The Ball Rolling",
        buttonFooterText:
          "By completing this form your details are shared with New Build Inspections, but absolutely no one else. Note that any quotes or information provided are subject to New Build Inspections terms & conditions and privacy policy",
      },
      {
        formStep: 2,
        heading: "Snagging Survey",
        components: [{ component: SnaggingThankYou, label: "Thank You" }],
      },
    ],
  },

  // ------ Specialist Lending ------ //

  SpecialistLending: {
    FormType1: [
      {
        formStep: 0,
        heading: "Specialist Lending",
        header:
          "Fill in your details so a specialist broker can get in touch or give them a call on 01454 809 300",
        components: [
          { component: YourDetails, label: "Your Details" },
          { component: OtherInformation },
          { component: Newsletter },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "By completing this form your details will be shared with Chartwell Funding only. YOUR PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE OR ANY DEBT SECURED ON IT. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
      },
      {
        formStep: 1,
        heading: "Specialist Lending",
        components: [
          { component: SpecialistLendingThankYou, label: "Thank You" },
        ],
      },
    ],
  },

  // ------ Lease Expert ------ //

  LeaseExpert: {
    FormType1: [
      {
        formStep: 0,
        heading: "Find a Lease Expert",
        header:
          "Are you thinking of extending your lease, buying your freehold or applying for the right to manage? We can connect you with our expert lease solicitors for a free consultation and quote.",
        components: [
          { component: YourDetails, label: "Your Details" },
          { component: OtherInformation },
          {
            component: WhichServices,
            label: "Which Services Are You Interested In?",
          },
          {
            component: ContactHow,
            label: "How Would You Like To Be Contacted?",
          },
          { component: Newsletter },
        ],
        buttonText: "Submit",
        buttonFooterText:
          "By completing this form your details are only shared with Bonallack and Bishop; members of the Association of Leasehold Enfranchisement Practitioners with over 25 years of experience. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
      },
      {
        formStep: 1,
        heading: "Find a Lease Expert",
        components: [{ component: LeaseExpertThankYou, label: "Thank You" }],
      },
    ],
  },

  // ------ BestEA ------ //

  BestEA: {
    FormType1: [
      {
        formStep: 0,
        heading: "Best Estate Agent",
        components: [{ component: BestEA, label: "Property Details" }],
        buttonText: "Compare Estate Agents",
        buttonFooterText:
        "By proceeding you agree to be bound by the Terms & Conditions and Privacy Policy which can be found at the bottom of this page. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
    },
      {
        formStep: 1,
        heading: "Best Estate Agent",
        components: [
          { component: SpecialistLendingThankYou, label: "Thank You" },
        ],
      },
    ],
  },

  // ------ EA4Me ------ //

  EA4Me: {
    FormType1: [
      {
        formStep: 0,
        heading: "Best Estate Agent",
        components: [{ component: EA4Me, label: "Property Details" }],
        buttonText: "Submit",
        buttonFooterText:
          "By proceeding you agree to be bound by the Terms & Conditions and Privacy Policy which can be found at the bottom of this page. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
      },
    ],
  },

  // ------ EA4Me 2024------ //

  EA4Me2024: {
    FormType1: [
      {
        formStep: 0,
        heading: "Best Estate Agent",
        components: [{ component: EA4Me, label: "Property Details" }],
        buttonText: "Submit",
        buttonFooterText:
          "By proceeding you agree to be bound by the Terms & Conditions and Privacy Policy which can be found at the bottom of this page. This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.",
      },
    ],
  },
};
