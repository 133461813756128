// SubmitButton.js

import React, { useContext } from "react";
import PropTypes from "prop-types";

// Context
import { DesktopContext } from "../../App";

// MUI
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import DialogLink from "../dialog/DialogLink";

function SubmitButton({
  buttonText,
  handleSubmit,
  onSubmit,
  buttonFooterText,
  isSubmitting,
  disabled,
  service,
}) {
  const desktop = useContext(DesktopContext);

  var rightCol = 12;
  if (desktop) {
    rightCol = 6;
  }

  const processedFooterText = buttonFooterText
    ? buttonFooterText
        .replace(
          "terms & conditions",
          `<a id="termsLink" href="#">terms & conditions</a>`
        )
        .replace(
          "privacy policy",
          `<a id="privacyPolicyLink" href="#">privacy policy</a>`
        )
    : "";

  return (
    <Grid container spacing={0}>
      {desktop && <Grid item xs={6}></Grid>}
      <Grid item xs={rightCol}>
        <Button
          disabled={isSubmitting || disabled}
          size="medium"
          sx={{
            width: "100%",
            marginTop: "8px",
            fontSize: "1.1rem",
            fontWeight: 600,
          }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disableElevation
        >
          {buttonText}
        </Button>
      </Grid>

      {desktop && <Grid item xs={6}></Grid>}
      <Grid item xs={rightCol}>
        <Typography
          sx={{
            lineHeight: "1.5 !important",
            fontSize: "0.8rem !important",
          }}
          style={{ marginTop: "8px" }}
          dangerouslySetInnerHTML={{ __html: processedFooterText }}
        />
        <DialogLink service={service} />
      </Grid>
    </Grid>
  );
}

SubmitButton.propTypes = {
  buttonText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonFooterText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  service: PropTypes.string,
};

export default SubmitButton;
