import * as yup from "yup";

export const yupFirstName = yup
  .string()
  .required("Please enter your First Name");

export const yupLastName = yup.string().required("Please enter your Last Name");

export const yupFullName = yup.string().required("Please enter your Name");

export const yupAddressLine1 = yup
  .string()
  .required("Please enter Address Line 1");

export const yupDevelopmentName = yup
  .string()
  .required("Please enter the name of the Development");

export const yupEmail = yup
  .string()
  .email()
  .required("Please enter your Email Address");

export const yupPhone = yup
  .string()
  .required("Please enter your Telephone Number")
  .matches(/^[0-9 ]+$/, "Please enter numbers only for the Phone Number")
  .min(4, "Use 0000 if you don't wish to provide your phone number");

export const yupPostcode = yup
  .string()
  .required("Please enter your Postcode")
  .matches(
    /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i,
    "Please enter a valid UK Postcode"
  );

export const yupOutcode = yup
  .string()
  .required("Please enter your Postcode")
  .matches(
    /^(?:[A-Z]{1,2}[0-9R][0-9A-Z]?|[A-Z]{1,2}[0-9R][0-9A-Z]?[ ]?[0-9][A-Z]{2})$/i,
    "Please enter a valid UK Postcode"
  );

export const yupPropertySize = yup
  .string()
  .required("Please select a Property Size");

export const yupPropertyValue = yup
  .string()
  .required("Please select the Property Value");

export const yupPropertyValueAny = yup
  .number()
  .required("Please select the Property Value");

export const yupPropertyType = yup.string().required("Please select an option");

export const yupAppointWhen = yup.string().required("Please select an option");

export const yupIama = yup.string().required("Please select an option");

// Bridging Loan Calculator
export const yupLoanAmount = yup
  .number()
  .typeError("Please enter a numeric value")
  .required("Please enter the amount you wish to borow")
  .min(25000, "The minimum loan amount is £25K")
  .max(2500000, "The maximum loan amount is £2.5M");

export const yupPropertyValueSecurity1 = yup
  .number()
  .typeError("Please enter a numeric value")
  .required("Please enter the amount the value of your property")
  .min(25000, "The minimum property value is £25K");

export const yupMortgageBalance1 = yup
  .number()
  .typeError("Please enter a numeric value")
  .required("Please enter the oustanding mortage balance on your property")
  .max(2500000, "The maximum prooperty is £2,500,000");

export const yupPropertyValueSecurity2 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupMortgageBalance2 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupPropertyValueSecurity3 = yup
  .number()
  .typeError("Please enter a numeric value")

  .min(25000, "The minimum property value is £25,000")
  .max(2500000, "The maximum property is £2,500,000");

export const yupMortgageBalance3 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupPropertyValueSecurity4 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupMortgageBalance4 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupPropertyValueSecurity5 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

export const yupMortgageBalance5 = yup
  .number()
  .typeError("Please enter a numeric value")

  .max(2500000, "The maximum property is £2,500,000");

// Mortgage Cost Calculator
export const yupMortgageAmount = yup
  .string()
  .required("Mortgage Amount is required")
  .test("is-valid-number", "Please enter a numeric value", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return !isNaN(parsedNumber);
  })
  .test("min", "The minimum loan amount is £25K", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return parsedNumber >= 25000;
  })
  .test("max", "The maximum loan amount is £2.5M", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return parsedNumber <= 2500000;
  })
  .nullable();

export const yupMortgageAmount2 = yup
  .string()
  .required("Mortgage Amount is required")
  .test("is-valid-number", "Please enter a numeric value", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return !isNaN(parsedNumber);
  })
  .test("min", "The minimum loan amount is £25K", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return parsedNumber >= 25000;
  })
  .test("max", "The maximum loan amount is £2.5M", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return parsedNumber <= 2500000;
  })
  .nullable();

export const yupLoanTerm = yup.string().required("Loan Term is required");

export const yupLoanTerm2 = yup.string().required("Loan Term is required");

export const yupInterestRate = yup
  .string()
  .required("Interest Rate is required");

export const yupInterestRate2 = yup
  .string()
  .required("Interest Rate is required");

export const yupSalary = yup
  .string()
  .required("Please enter your salary")
  .test("is-valid-number", "Please enter your salary", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return !isNaN(parsedNumber);
  })
  .test("min", "The minimum salary is £1,000", (value) => {
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return parsedNumber >= 1000;
  })

  .nullable();

export const yupPartnerSalary = yup
  .string()
  .test("is-valid-number", "Please enter a numeric value", function (value) {
    if (!value) {
      // Skip validation if value is not provided
      return true;
    }
    const parsedNumber = parseInt(value.replace(/,/g, ""), 10);
    return !isNaN(parsedNumber);
  })
  .nullable();
